import { getIconForPostingType } from '@/util/utils';
import { getCategoryLabel, getTeamLabel, i18n } from '@/util/i18n';

export default {
  postings(state) {
    return state.results?.postings;
  },
  postingsTotal(state) {
    return state.results?.total;
  },
  aggregations(state) {
    return state.results?.aggregations;
  },
  results(state) {
    return state.results;
  },
  canLoadMore(state, getters) {
    return getters.postings?.length < getters.postingsTotal;
  },
  matchDays(state) {
    return state.results?.aggregations?.matchDays.map((bucket) => bucket.key_as_string);
  },
  types(state) {
    return state.types;
  },
  categories(state) {
    return state.categories;
  },
  allCategories(state, getters) {
    if (!state.categoryList) {
      return [];
    }

    return state.categoryList.map((c) => ({
      value: c.id,
      label: getCategoryLabel(c.id, state.categoryList, state.currentLocale),
      active: getters.categories.indexOf(c.id) > -1,
    }));
  },
  teams(state) {
    return state.teams;
  },
  allTeams(state, getters) {
    if (state.teamList && state.cupProfile) {
      let { teams } = state.cupProfile;

      if (!Array.isArray(teams)) {
        teams = Object.keys(teams);
      }

      return teams.map((code) => {
        let r = {
          value: code,
          label: getTeamLabel(code, state.teamList, state.currentLocale),
          active: getters.teams.indexOf(code) > -1,
        };

        const config = state.teamList.find((t) => t.code === code);

        if (config) {
          r = { ...r, ...config };
        }

        return r;
      });
    }

    return [];
  },
  isValidContestant(state) {
    return (contestantId) => {
      if (contestantId === '-') {
        return false;
      }

      const team = state.teamList.find((team) => team.code === contestantId);
      const names = Object.values(team?.name ?? {});

      // Handle anonymous contestant hack introduced by Tom Jewell (name "-") for RG24
      if (names.includes('-')) {
        return false;
      }

      return true;
    };
  },
  date(state) {
    return state.date;
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  sortOrder(state) {
    return state.sortOrder;
  },
  isRecommended(state) {
    return state.recommended;
  },
  isLocked(state) {
    return state.locked;
  },
  availableTypes(state) {
    return state.cupProfile?.postingTypes ?? [];
  },
  availableTags(state) {
    return state.cupProfile?.tags ?? [];
  },
  contestantType(state) {
    return state.cupProfile?.contestantType ?? 'team';
  },
  logoutUrl(state) {
    return state.logoutUrl;
  },
  activePosting(state, getters) {
    if (!state.activePosting) {
      return null;
    }

    if (!getters.postings) {
      return state.activePosting;
    }

    // Try to find the active posting in the postings array, to be able to receive live updates (via firestore)
    const posting = getters.postings.find((p) => p.id === state.activePosting.id);

    // Fallback to the activePosting state
    // e.g. when mounted with an open preview (postingPreview route) for a posting, that is not in the initial payload
    return posting ?? state.activePosting;
  },
  activeDropDownFilter(state) {
    return state.activeDropDownFilter;
  },
  sortOptions(state, getters) {
    let options = [
      {
        label: i18n.t('filter.sort.options.published.desc'),
        value: 'published_desc',
        active: getters.sortOrder === 'published_desc',
      },
      {
        label: i18n.t('filter.sort.options.published.asc'),
        value: 'published_asc',
        active: getters.sortOrder === 'published_asc',
      },
    ];

    if (getters.showDownloadCounts) {
      options = [
        ...options,
        ...[
          {
            label: i18n.t('filter.sort.options.downloads.desc'),
            value: 'downloads_desc',
            active: getters.sortOrder === 'downloads_desc',
          },
          {
            label: i18n.t('filter.sort.options.downloads.asc'),
            value: 'downloads_asc',
            active: getters.sortOrder === 'downloads_asc',
          },
        ],
      ];
    }

    return options;
  },
  activeFilters(state, getters) {
    const filters = [];
    if (getters.types.length > 0) {
      getters.types.forEach((code) => {
        filters.push({
          filterKey: 'types',
          value: code,
          label: i18n.t(`posting.type.${code.toLowerCase()}`),
          icon: getIconForPostingType(code),
        });
      });
    }

    if (getters.categories.length > 0) {
      getters.categories.forEach((code) => {
        filters.push({
          filterKey: 'categories',
          value: code,
          label: getCategoryLabel(code, state.categoryList, state.currentLocale),
        });
      });
    }

    if (getters.teams.length > 0) {
      getters.teams.forEach((code) => {
        const row = {
          filterKey: 'teams',
          value: code,
          label: getTeamLabel(code, state.teamList, state.currentLocale),
        };

        const config = state.teamList.find((t) => t.code === code);

        if (config && config.countryCode) {
          if (config.isFifaCode) {
            row.icon = `<span class="flag flag-fifa-${config.countryCode.toLowerCase()}"/>`;
          } else {
            row.icon = `<span class="flag flag-${config.countryCode.toLowerCase()}"/>`;
          }
        }

        filters.push(row);
      });
    }

    if (getters.date) {
      filters.push({
        filterKey: 'date',
        value: getters.date,
        label: getters.date,
        icon: ['far', 'calendar-alt'],
      });
    }

    return filters;
  },
  defaultLocale(state) {
    return state.cupProfile?.defaultLocale;
  },
  currentLang(state) {
    return state.currentLocale.substr(0, 2);
  },
  showLocaleMenu(state) {
    return state.cupProfile?.locales?.length > 1;
  },
  tabs(state) {
    return state.cupProfile?.tabs;
  },
  activeTabObject(state, getters) {
    if (getters.tabs && state.activeTab) {
      return getters.tabs.find((tab) => tab.id === state.activeTab || tab.code === state.activeTab);
    }

    return null;
  },
  filterConfigForActiveTab(state, getters) {
    return getters.activeTabObject?.filterConfig;
  },
  showCategoryFilter(state, getters) {
    return getters.filterConfigForActiveTab?.showCategoryFilter;
  },
  showCategoryInfo(state, getters) {
    return !(getters.filterConfigForActiveTab && !getters.showCategoryFilter);
  },
  updateCheckEnabled(state, getters) {
    return getters.sortOrder === 'published_desc';
  },
  updateAvailable(state) {
    return state.updateAvailable;
  },
  updateInterval() {
    return 10;
  },
  fetchParams(state, getters) {
    return {
      types: getters.types || [],
      categories: getters.categories || [],
      teams: getters.teams,
      created: getters.date,
      recommended: getters.isRecommended || null,
      term: getters.searchTerm,
      sortOrder: getters.sortOrder,
      tab: state.activeTab,
      tags: state.activeTags,
      filterTags: state.activeFilterTags,
    };
  },
  gridLoading(state) {
    return state.gridLoading;
  },
  mobileFilterMenuVisible(state) {
    return state.mobileFilterMenuVisible;
  },
  isMobile(state) {
    return state.isMobile;
  },
  creatorTimezoneLabel(state, getters) {
    if (state.cupProfile?.creatorTimezoneLabel) {
      return state.cupProfile.creatorTimezoneLabel[state.currentLocale] ?? state.cupProfile.creatorTimezoneLabel[getters.defaultLocale];
    }

    return null;
  },
  showDownloadCounts(state) {
    return state.cupProfile?.showDownloadCounts ?? false;
  },
  matches(state) {
    return state.cupProfile?.matches;
  },
  allMatchDays(state) {
    if (state.cupProfile?.matchDays) {
      return state.cupProfile.matchDays.sort();
    }

    return [];
  },
  accessPermissions(state) {
    return state.cupProfile?.accessPermissions;
  },
};
