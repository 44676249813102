import * as types from './mutation-types';
import {
  getPmaCollectionObserver,
  getNearLiveCollectionObserver,
  getNearLiveCollectionObserverByDay,
} from '@/util/firebase';
import {
  backendService,
  LOG_ACTION_DISMISS,
  LOG_ACTION_PREVIEW,
} from '@/services/BackendService';
import { sortArray } from '@/util/utils';
import moment from '@/util/moment';
import LogService from '@/services/LogService';

function getActiveEntity() {
  const path = window.location.pathname;

  if (path === '/near-live') {
    return 'NearLive';
  }

  if (path === '/pma-content') {
    return 'PMA';
  }

  return null;
}

export default {
  bindNearLivePostings({
    state, getters, commit, dispatch,
  }) {
    if (getters.inMatchMode && !state.match) {
      return;
    }

    if (getters.inDayMode && !state.matchDay) {
      return;
    }

    // Cancel previous subscriptions
    dispatch('unbindPostings');

    const observer = ((snapshot) => {
      const postings = snapshot.docs.map((doc) => ({
        ...doc.data(),
        type: 'nearlive',
      }));
      sortArray(postings, 'captured', 'desc');
      commit(types.SET_POSTINGS, postings);
    });

    if (getters.inMatchMode) {
      const unsubscribeFn = getNearLiveCollectionObserver(state.match.id, observer);
      commit(types.SET_POSTING_UNSUBSCRIBE_FN, unsubscribeFn);
    } else {
      const unsubscribeFn = getNearLiveCollectionObserverByDay(state.matchDay, observer);
      commit(types.SET_POSTING_UNSUBSCRIBE_FN, unsubscribeFn);
    }
  },
  bindPmaPostings({ commit, dispatch }) {
    dispatch('unbindPostings');

    const unsubscribeFn = getPmaCollectionObserver((snapshot) => {
      const postings = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        type: 'pma',
      }));
      sortArray(postings, 'captured', 'desc');
      commit(types.SET_POSTINGS, postings);
    });

    commit(types.SET_POSTING_UNSUBSCRIBE_FN, unsubscribeFn);
  },
  setMatch({ commit, dispatch }, match) {
    commit(types.SET_POSTINGS, []);
    commit(types.SET_MATCH, match);
    dispatch('bindNearLivePostings');
    dispatch('saveSession');
  },
  setMatchDay({ commit, dispatch }, matchDay) {
    commit(types.SET_POSTINGS, []);
    commit(types.SET_MATCH_DAY, matchDay);
    dispatch('bindNearLivePostings');
    dispatch('saveSession');
  },
  unbindPostings({ state, commit }) {
    commit(types.SET_POSTINGS, []);
    if (state.postingUnsubscribeFn) {
      state.postingUnsubscribeFn();
      commit(types.SET_POSTING_UNSUBSCRIBE_FN, null);
    }
  },
  setActivePosting({ commit }, posting) {
    commit(types.SET_ACTIVE_POSTING, posting);
  },
  showPreview({ dispatch }, posting) {
    dispatch('setActivePosting', posting);
    dispatch('createTrackingLog', {
      Action: LOG_ACTION_PREVIEW,
      Entity: getActiveEntity(),
      EntityID: posting.id,
    }, {
      root: true,
    });
    LogService.event(getActiveEntity(), 'preview', posting.id);
  },
  closePreview({
    commit, state, dispatch,
  }) {
    if (state.activePosting) {
      dispatch('createTrackingLog', {
        Action: LOG_ACTION_DISMISS,
        Entity: getActiveEntity(),
        EntityID: state.activePosting.id,
      }, {
        root: true,
      });
      LogService.event(getActiveEntity(), 'dismiss', state.activePosting.id);
    }
    commit(types.SET_ACTIVE_POSTING, null);
  },
  getSignedUrl(_, data) {
    return backendService.getSignedNearLiveUrl(data);
  },
  setViewMode({ commit, dispatch }, viewMode) {
    commit(types.SET_VIEW_MODE, viewMode);
    dispatch('saveSession');
  },
  loadSession({
    state, commit, getters, rootGetters,
  }) {
    try {
      let session = sessionStorage.getItem('smp-nearLiveState');

      const matches = getters.availableMatches;
      const matchDays = rootGetters.allMatchDays;

      const now = moment.utc();
      const startedMatchDays = matchDays.filter((day) => moment(day).startOf('day').isBefore(now)).sort().reverse();
      const latestMatchDay = startedMatchDays.length > 0 ? startedMatchDays[0] : matchDays[0];

      const latestMatch = (() => {
        if (getters.inMatchMode && matches.length > 0) {
          // load the most recent match
          const now = moment.utc();
          const startedMatches = matches.filter((match) => moment(match.kickoff).isBefore(now));
          sortArray(startedMatches, 'kickoff', 'desc');

          return startedMatches[0];
        }

        return null;
      })();

      if (session) {
        session = JSON.parse(session);

        if (session.viewMode && getters.inMatchMode) {
          commit(types.SET_VIEW_MODE, session.viewMode);
        } else {
          commit(types.SET_VIEW_MODE, 'grid');
        }

        if (getters.inMatchMode) {
          if (session.match && matches.length > 0 && matches.find((m) => m.id === session.match.id)) {
            commit(types.SET_MATCH, session.match);
          } else if (latestMatch) {
            commit(types.SET_MATCH, latestMatch);
          }
        }

        if (getters.inDayMode) {
          commit(types.SET_MATCH_DAY, session.matchDay ?? latestMatchDay);
        }
      } else { // No active session found, load some defaults...
        if (latestMatch && !state.match) {
          commit(types.SET_MATCH, latestMatch);
        }

        if (getters.inDayMode && !state.matchDay) {
          commit(types.SET_MATCH_DAY, latestMatchDay);
        }

        if (!state.viewMode) {
          commit(types.SET_VIEW_MODE, 'grid');
        }
      }
    } catch (e) {
      // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
    }
  },
  saveSession({ state }) {
    try {
      sessionStorage.setItem('smp-nearLiveState', JSON.stringify({
        match: state.match,
        matchDay: state.matchDay,
        viewMode: state.viewMode,
      }));
    } catch (e) {
      // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
    }
  },
};
